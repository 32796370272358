import { convertNulls } from './utils.js';

$(document).on('turbolinks:load', function () {
  const electricityCtx = document.getElementById('electricityLineChart');
  if (electricityCtx) {
    createEnergyLineChart(electricityCtx, 'electricity', 'kWh', '#ffaf00');
  }
});

function createEnergyLineChart(ctx, property, unit, color) {
  new Chart(ctx, {
    type: 'line',
    data: {
      labels: JSON.parse(ctx.dataset.keys),
      datasets: [{
        label: property.charAt(0).toUpperCase() + property.slice(1),
        fill: false,
        backgroundColor: color,
        borderWidth: 2,
        hoverBorderWidth: 5,
        borderColor: color,
        pointRadius: 0,
        pointHoverRadius: 5,
        data: convertNulls(JSON.parse(ctx.dataset[`${property}Dataset`])),
        spanGaps: true,
      }]
    },
    options: optionsForEnergyChart(unit),
  });
}

function optionsForEnergyChart(yAxesLabel) {
  return {
    legend: {
      labels: {
        fontSize: 22,
        padding: 20,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            fontSize: 15,
            maxRotation: 90,
            minRotation: 90,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          scaleLabel: {
            display: true,
            labelString: yAxesLabel,
            fontSize: 20,
            padding: 10,
          },
        },
      ],
    },
    tooltips: {
      mode: 'nearest',
      intersect: false,
      backgroundColor: '#313a46',
      titleFontSize: 20,
      bodyFontSize: 20,
      xPadding: 15,
      yPadding: 15,
    },
  };
}
