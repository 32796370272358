import { convertNulls } from './utils.js';

$(document).on('turbolinks:load', function () {
  const colors = ['#68c5a4', '#e7f195', '#9ad6a4', '#f8e38d', '#c5e89f'];

  const temperaturesCtx = document.getElementById('temperatureLineChart');
  if (temperaturesCtx) {
    const temperaturesDatasets = createEventsDatasets(temperaturesCtx, 'temperatures', colors);
    createEventsLineChart(temperaturesCtx, temperaturesDatasets, '°C');
  }
});

function createEventsLineChart(ctx, datasets, yAxesLabel) {
  new Chart(ctx, {
    type: 'line',
    data: {
      labels: JSON.parse(ctx.dataset.keys),
      datasets: datasets,
    },
    options: optionsForEventsLineChart(yAxesLabel),
  });
}

function createEventsDatasets(ctx, property, colors) {
  return JSON.parse(ctx.dataset[`${property}Datasets`])
    .sort((first, second) => {
      return first.name < second.name ? -1 : 1;
    })
    .map((data, index) => ({
      label: data.name,
      fill: false,
      backgroundColor: colors[index % colors.length],
      borderWidth: 3,
      hoverBorderWidth: 7,
      borderColor: colors[index % colors.length],
      pointRadius: 1,
      pointHoverRadius: 7,
      data: convertNulls(data[property]),
      spanGaps: true,
    }));
}

function optionsForEventsLineChart(yAxesLabel) {
  return {
    legend: {
      display: false,
      labels: {
        fontSize: 22,
        padding: 20,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            fontSize: 15,
            maxRotation: 90,
            minRotation: 90,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          scaleLabel: {
            display: true,
            labelString: yAxesLabel,
            fontSize: 20,
            padding: 10,
          },
        },
      ],
    },
    tooltips: {
      mode: 'nearest',
      intersect: false,
      backgroundColor: '#313a46',
      titleFontSize: 20,
      bodyFontSize: 20,
      xPadding: 15,
      yPadding: 15,
    },
  };
}
