Date.prototype.subtractHours = function (hours) {
  this.setTime(this.getTime() - hours * 60 * 60 * 1000);
  return this;
};

$(document).on('turbolinks:load', function () {
  let presetButtons = Array.from($('.preset-btn'));
  presetButtons.forEach((button) => {
    button.addEventListener('click', (event) => {
      let currentDate = new Date();
      let hours = $(event.target).val();
      $('#to_date').val(formatDate(currentDate));
      $('#from_date').val(formatDate(currentDate.subtractHours(hours)));
      $('form').submit();
    });
  });

  let checkbox = $('#custom_dates');
  checkbox.change(function () {
    $('#custom-range').toggleClass('d-none d-flex');
    $('#preset-range').toggleClass('d-none d-flex');
    $('.preset-btn').removeClass('disabled');
  });
});

function formatDate(date) {
  return (
    `${date.getFullYear()}-${zeroFill(date.getMonth() + 1, 2)}-` +
    `${zeroFill(date.getDate(), 2)}T${zeroFill(date.getHours(), 2)}:` +
    `${zeroFill(date.getMinutes(), 2)}`
  );
}

function zeroFill(number, width) {
  width -= number.toString().length;
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
  }
  return number + '';
}
