$(document).on('turbolinks:load', function () {
  const tvocCtx = document.getElementById('tvocMappingChart');
  if (tvocCtx) {
    const rawDataset = JSON.parse(tvocCtx.dataset['tvocInfo']);
    if (rawDataset == null){
      return
    }

    const tvocDataset = createTvocDataset(rawDataset);
    createTvocMappingChart(tvocDataset);
  }
});

function createTvocDataset(rawDataset) {
  return rawDataset.map((sensor) => {
    return {
      name: sensor.name,
      averagetvoc: sensor.average || 'N/D',
      color: setBrightnessOfGrey(sensor.average),
      data: [[sensor.x, sensor.y]],
      marker: {
        symbol: 'circle',
        radius: 10,
        lineColor: 'black',
        lineWidth: 1
      }
    }
  })
}

function createTvocMappingChart(dataset) {
  Highcharts.chart('tvocMappingChart', {
    chart: {
      type: 'scatter',
      plotBackgroundImage: 'healthy-workspaces.png',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      gridLineWidth: 1,
      tickInterval: 10,
      min: 0,
      max: 110,
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    yAxis: {
      tickInterval: 10,
      min: 0,
      max: 110,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<b>{series.name}:</b><br>{series.options.averagetvoc} ppb',
      pointFormat: '',
      backgroundColor: '#ffffff',
      borderColor: 'black',
      padding: 15,
      style: {
        fontSize: 20,
      },
    },
    series: dataset
  });
}

function setBrightnessOfGrey(average) {
  switch (true) {
    case (average == null):
      return 'white';
    case (average <= 40):
      return '#DCDCDC';
    case (average <= 50):
      return '#D3D3D3';
    case (average <= 60):
      return '#C8C8C8';
    case (average <= 70):
      return '#B8B8B8';
    case (average <= 80):
      return '#A9A9A9';
    case (average <= 90):
      return '#989898';
    case (average <= 120):
      return '#888888';
    case (average <= 180):
      return '#707070';
    case (average <= 250):
      return '#585858';
    default:
      return '#303030';
  }
}
