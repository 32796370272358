// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("chart.js");
require("./date_range");
require("./feedbacks");
require("./events_summary_charts");
require("./temperature_line_chart");
require("./tvoc_line_chart");
require("./position_heatmap");
require("./energy_chart");
require("./appliances_chart");
require("./desk_occupancy_chart");
require("./carbon_dioxide_mapping_chart");
require("./temperature_mapping_chart");
require("./tvoc_mapping_chart");
require("./carbon_dioxide_line_chart");
require("./particulate_line_chart");
require("./particulate_mapping_chart");
require("./autoreload");
require("./sensor_select");


import "bootstrap/dist/js/bootstrap";
import "@fortawesome/fontawesome-free/js/all";

require("../stylesheets/application.scss");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
