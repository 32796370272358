$(document).on('turbolinks:load', function () {
  const carbonDioxideCtx = document.getElementById('carbonDioxideMappingChart');
  if (carbonDioxideCtx) {
    const rawDataset = JSON.parse(carbonDioxideCtx.dataset['carbonDioxideInfo']);
    if (rawDataset == null){
      return
    }

    const carbonDioxideDataset = createCarbonDioxideDataset(rawDataset);
    createCarbonDioxideMappingChart(carbonDioxideDataset);
  }
});

function createCarbonDioxideDataset(rawDataset) {
  return rawDataset.map((sensor) => {
    return {
      name: sensor.name,
      averageCarbonDioxide: sensor.average || 'N/D',
      color: getBlueShade(sensor.average),
      data: [[sensor.x, sensor.y]],
      marker: {
        symbol: 'circle',
        radius: 10
      }
    }
  })
}

function createCarbonDioxideMappingChart(dataset) {
  Highcharts.chart('carbonDioxideMappingChart', {
    chart: {
      type: 'scatter',
      plotBackgroundImage: 'healthy-workspaces.png',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      gridLineWidth: 1,
      tickInterval: 10,
      min: 0,
      max: 110,
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    yAxis: {
      tickInterval: 10,
      min: 0,
      max: 110,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<b>{series.name}:</b><br>{series.options.averageCarbonDioxide} ppm',
      pointFormat: '',
      backgroundColor: '#ffffff',
      borderColor: 'black',
      padding: 15,
      style: {
        fontSize: 20,
      },
    },
    series: dataset
  });
}

function getBlueShade(average) {
  switch (true) {
    case (average == null):
      return 'grey';
    case (average <= 400):
      return '#00CCFF';
    case (average <= 1000):
      return '#0099FF';
    case (average <= 2000):
      return '#0033FF';
    case (average <= 5000):
      return '#0000FF';
    case (average <= 4000):
      return '#000099';
    case (average > 4000):
      return '#000033';
    default:
      return 'grey';
  }
}
