import { convertNulls } from './utils.js';

$(document).on('turbolinks:load', function () {
  const averageTemperaturesCtx = document.getElementById('averageTemperaturesChart');
  if (averageTemperaturesCtx) {
    createSummaryLineChart(averageTemperaturesCtx, 'temperatures', '#f5d661')
  }

  const averageLightsCtx = document.getElementById('averageLightsChart');
  if (averageLightsCtx) {
    createSummaryLineChart(averageLightsCtx, 'lights', '#84ebd4')
  }

  const averageCarbonDioxidesCtx = document.getElementById('averageCarbonDioxidesChart');
  if (averageCarbonDioxidesCtx) {
    createSummaryLineChart(averageCarbonDioxidesCtx, 'carbonDioxides', '#76e2eb')
  }

  const averageVirusRisksCtx = document.getElementById('averageVirusRisksChart');
  if (averageVirusRisksCtx) {
    createSummaryLineChart(averageVirusRisksCtx, 'virusRisks', '#f28656')
  }
});

function createSummaryLineChart(ctx, property, color) {
  new Chart(ctx, {
    type: 'line',
    data: {
      labels: JSON.parse(ctx.dataset.keys),
      datasets: [{
        fill: false,
        borderWidth: 3,
        borderColor: color,
        backgroundColor: color,
        pointRadius: 0,
        spanGaps: true,
        data: convertNulls(JSON.parse(ctx.dataset[`${property}Datasets`])),
      }]
    },
    options: optionsForSummaryCharts(color),
  });
}

function optionsForSummaryCharts(color) {
  return {
    legend: {
      display: false
    },
    layout: {
      padding: {
        top: 15,
        bottom: 15
      }
    },
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            display: false
          },
        },
      ],
      yAxes: [
        {
          display: false,
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            display: false
          },
        },
      ],
    },
    tooltips: {
      mode: 'nearest',
      backgroundColor: color,
      titleFontSize: 20,
      bodyFontSize: 20,
      xPadding: 15,
      yPadding: 15,
    },
  };
}
