$(document).on('turbolinks:load', function () {
  const temperatureCtx = document.getElementById('temperatureMappingChart');
  if (temperatureCtx) {
    const rawDataset = JSON.parse(temperatureCtx.dataset['temperatureInfo']);
    if (rawDataset == null){
      return
    }

    const temperatureDataset = createTemperatureDataset(rawDataset);
    createTemperatureMappingChart(temperatureDataset);
  }
});

function createTemperatureDataset(rawDataset) {
  return rawDataset.map((sensor) => {
    return {
      name: sensor.name,
      averageTemperature: sensor.average || 'N/D',
      color: setTemperatureColor(sensor.average),
      data: [[sensor.x, sensor.y]],
      marker: {
        symbol: 'circle',
        radius: 10
      }
    }
  })
}

function createTemperatureMappingChart(dataset) {
  Highcharts.chart('temperatureMappingChart', {
    chart: {
      type: 'scatter',
      plotBackgroundImage: 'healthy-workspaces.png',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      gridLineWidth: 1,
      tickInterval: 10,
      min: 0,
      max: 110,
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    yAxis: {
      tickInterval: 10,
      min: 0,
      max: 110,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<b>{series.name}:</b><br>{series.options.averageTemperature} ˚C',
      pointFormat: '',
      backgroundColor: '#ffffff',
      borderColor: 'black',
      padding: 15,
      style: {
        fontSize: 20,
      },
    },
    series: dataset
  });
}

function setTemperatureColor(average) {
  switch (true) {
    case (average == null):
      return 'grey';
    case (average <= 14):
      return '#A9A9A9';
    case (average <= 16):
      return '#C0C0C0';
    case (average <= 18):
      return '#D8D8D8';
    case (average <= 20):
      return '#00CC66';
    case (average <= 22):
      return '#009933';
    case (average <= 24):
      return '#006600';
    case (average <= 26):
      return '#F80000 ';
    case (average <= 28):
      return '#800000';
    default:
      return '#500000';
  }
}
