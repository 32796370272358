$(document).on('turbolinks:load', function () {
  let sensorSelector = document.querySelector('#sensor-selector');

  if (sensorSelector) {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search.slice(1));

    sensorSelector.addEventListener('change', () => {
      params.delete('id');
      params.append('id', sensorSelector.value);
      window.location.href = url.origin + url.pathname + '?' + params.toString();
    })
  }
});
