$(document).on('turbolinks:load', function () {
  const positionsCtx = document.getElementById('positionsHeatmapChart');
  if (positionsCtx) {
    const rawDataset = JSON.parse(positionsCtx.dataset['matrix']);
    const xMax = JSON.parse(positionsCtx.dataset['xMax']);
    const yMax = JSON.parse(positionsCtx.dataset['yMax']);
    positionsDataset = createPositionsDataset(rawDataset);
    createPositionsHeatmapChart(positionsDataset, xMax, yMax);
  }
});

function createPositionsDataset(rawDataset) {
  let dataset = [];

  if (rawDataset) {
    rawDataset.forEach((raw, x) => {
      raw.forEach((value, y) => {
        dataset.push([x, y, value]);
      });
    });
  }

  return dataset;
}

function createPositionsHeatmapChart(dataset, xMax, yMax) {
  Highcharts.setOptions({
    lang: {
      thousandsSep: ',',
    },
  });

  Highcharts.chart('positionsHeatmapChart', {
    chart: {
      type: 'heatmap',
      plotBackgroundImage: 'emsol.png',
    },
    boost: {
      useGPUTranslations: true
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      gridLineWidth: 0,
      tickInterval: 10,
      min: 0,
      max: xMax,
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    yAxis: {
      gridLineWidth: 0,
      tickInterval: 10,
      min: 0,
      max: yMax,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    colorAxis: {
      stops: [
        [0, '#46ff3799'],
        [0.004, '#c2ff0499'],
        [0.008, '#edff0499'],
        [0.012, '#fff30499'],
        [0.02, '#ffdf0399'],
        [0.032, '#ffcb0399'],
        [0.064, '#ffb60299'],
        [0.128, '#ffa20299'],
        [0.256, '#ff7a0199'],
        [0.512, '#ff650199'],
        [1, '#ff290199'],
      ],
      min: 0,
      max: 250,
      reversed: false,
    },
    tooltip: {
      headerFormat: '',
      pointFormat: 'Total records for ({point.x}, {point.y}): <b>{point.value}</b>',
      backgroundColor: '#ffffff',
      borderColor: 'black',
      padding: 15,
      style: {
        fontSize: 20,
      },
    },
    legend: {
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'middle',
      symbolHeight: 400,
      symbolWidth: 25,
    },
    series: [
      {
        data: dataset,
        boostThreshold: 15000
      },
    ],
  });
}
