const SELECTED_COLOR = '#ffc107';
const UNSELECTED_COLOR = '#b6b6b6';

$(document).on('turbolinks:load', function () {
  $('.star').on('mouseover', function () {
    selectStar($(this));
  });

  $('.star').on('mouseout', function () {
    let currentRating = $(this).parent().prev()[0].getAttribute('value');
    setPreviousState($(this), currentRating);
  });

  $('.star').on('click', function () {
    selectStar($(this));
    changeRate($(this));
  });

  $('.feedback-topic').on('click', function () {
    selectTopic($(this));
  });
});

function changeRate(star) {
  let newRating = star[0].getAttribute('value');
  star.parent().prev()[0].setAttribute('value', newRating);
}

function selectStar(star) {
  star.children().children()[0].setAttribute('fill', SELECTED_COLOR);
  star.prevAll().each(function () {
    $(this).children().children()[0].setAttribute('fill', SELECTED_COLOR);
  });
  star.nextAll().each(function () {
    $(this).children().children()[0].setAttribute('fill', UNSELECTED_COLOR);
  });
}

function setPreviousState(star, currentRating) {
  let allStars = star.parent().children();
  allStars.each(function() {
    let starRating = $(this)[0].getAttribute('value');
    let color = (starRating <= currentRating) ? SELECTED_COLOR : UNSELECTED_COLOR;
    $(this).children().children()[0].setAttribute('fill', color);
  })
}

function selectTopic(topic) {
  topic.toggleClass('no-selected');
  let checkbox = topic.children().eq(2);
  checkbox.prop('checked', !checkbox.prop('checked'));
}
