$(document).on('turbolinks:load', function () {
  const deskOccupancyCtx = document.getElementById('deskOccupancyChart');
  if (deskOccupancyCtx) {
    const rawDataset = JSON.parse(deskOccupancyCtx.dataset['deskOccupancyInfo']);
    if (rawDataset == null){
      return
    }

    const deskOccupancyDataset = createDeskOccupancyDataset(rawDataset);
    createDeskOccupancyChart(deskOccupancyDataset);
  }
});

function createDeskOccupancyDataset(rawDataset) {
  return dataset = rawDataset.map((sensor) => {
    return {
      name: sensor.name,
      status: sensor.occupied ? 'OCCUPIED' : 'UNOCCUPIED',
      occupancyStatusFor: sensor.occupancy_status_for,
      color: sensor.occupied ? 'red' : 'green',
      data: [[sensor.x, sensor.y]],
      marker: {
        symbol: 'circle',
        radius: 10
      }
    }
  });
}

function createDeskOccupancyChart(dataset) {
  Highcharts.chart('deskOccupancyChart', {
    chart: {
      type: 'scatter',
      plotBackgroundImage: 'desk-area.png',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      gridLineWidth: 1,
      tickInterval: 10,
      min: 70,
      max: 110,
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    yAxis: {
      tickInterval: 10,
      min: 60,
      max: 100,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<b>{series.name}:</b><br>{series.options.status} for the last {series.options.occupancyStatusFor}',
      pointFormat: '',
      backgroundColor: '#ffffff',
      borderColor: 'black',
      padding: 15,
      style: {
        fontSize: 20,
      },
    },
    series: dataset
  });
}
