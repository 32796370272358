$(document).on('turbolinks:load', function () {
  const appliancesCtx = document.getElementById('appliancesChart');
  if (appliancesCtx) {
    createAppliancesLineChart(appliancesCtx);
  }
});

function createAppliancesLineChart(ctx) {
  new Chart(ctx, {
    type: 'line',
    data: { datasets: createUtilisationsDatasets(ctx).concat(createMeterReadingsDatasets(ctx)) },
    options: optionsForAppliancesChart(ctx.dataset.timeScale),
  });
}

function createUtilisationsDatasets(ctx) {
  return Object.entries(JSON.parse(ctx.dataset.utilisationsDataset)).map((sensor_info) => ({
    label: 'Utilisation',
    pointRadius: 1,
    backgroundColor: setOpacity('#a4fdab'),
    borderWidth: 2,
    borderColor: '#a4fdab',
    yAxisID: 'y',
    data: sensor_info[1].map((data) => ({
      x: data[0],
      y: data[1]
    }))
  }));
}

function createMeterReadingsDatasets(ctx) {
  return Object.entries(JSON.parse(ctx.dataset.meterReadingsDataset)).map((sensor_info) => ({
    label: 'Meter Reading',
    legend: {
      display: false
    },
    fill: false,
    pointRadius: 1,
    borderWidth: 2,
    borderColor: '#ffaf22',
    yAxisID: 'y1',
    data: sensor_info[1].map((data) => ({
      x: data[0],
      y: data[1]
    }))
  }));
}

function optionsForAppliancesChart(timeScale) {
  return {
    elements: {
      line: {
        tension: 0,
        borderWidth: 1
      }
    },
    legend: {
      labels: {
        fontSize: 22,
        padding: 20,
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          time: {
            unit: timeScale,
            displayFormats: {
              minute: 'HH:mm',
              hour: 'HH:mm',
              day: 'DD MMM HH:mm'
            }
          },
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
            fontSize: 15,
            maxRotation: 90,
            minRotation: 90,
          },
        },
      ],
      yAxes: [
        {
          id: 'y',
          position: 'right',
          gridLines: {
            drawOnChartArea: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Status',
            fontSize: 20,
            padding: 10,
          },
          ticks: {
            callback: function(value, index, values) {
              if (value == 0) {
                return 'Off';
              } else if (value == 1) {
                return 'On';
              }
            }
          }
        },
        {
          id: 'y1',
          title: 'kWh',
          gridLines: {
            drawOnChartArea: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'kWh',
            fontSize: 20,
            padding: 10,
          },
        },
      ],
    },
    tooltips: {
      mode: 'nearest',
      intersect: false,
      backgroundColor: '#313a46',
      titleFontSize: 20,
      bodyFontSize: 20,
      xPadding: 15,
      yPadding: 15,
    },
  };
}

function setOpacity(color) {
  return `#${ color.substring(1) }4D`;
}
