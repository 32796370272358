$(document).on('turbolinks:load', function () {
  const particulateCtx = document.getElementById('particulateMappingChart');
  if (particulateCtx) {
    const rawDataset = JSON.parse(particulateCtx.dataset['particulateInfo']);
    if (rawDataset == null){
      return
    }

    const particulateDataset = createParticulateDataset(rawDataset);
    createParticulateMappingChart(particulateDataset);
  }
});

function createParticulateDataset(rawDataset) {
  return rawDataset.map((sensor) => {
    return {
      name: sensor.name,
      averageparticulate: sensor.average || 'N/D',
      color: setBrightnessOfGrey(sensor.average),
      data: [[sensor.x, sensor.y]],
      marker: {
        symbol: 'circle',
        radius: 10,
        lineColor: 'black',
        lineWidth: 1
      }
    }
  })
}

function createParticulateMappingChart(dataset) {
  Highcharts.chart('particulateMappingChart', {
    chart: {
      type: 'scatter',
      plotBackgroundImage: 'healthy-workspaces.png',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      gridLineWidth: 1,
      tickInterval: 10,
      min: 0,
      max: 110,
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    yAxis: {
      tickInterval: 10,
      min: 0,
      max: 110,
      title: {
        text: null,
      },
      labels: {
        style: {
          fontSize: 18,
        },
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      headerFormat: '<b>{series.name}:</b><br>{series.options.averageparticulate} ppb',
      pointFormat: '',
      backgroundColor: '#ffffff',
      borderColor: 'black',
      padding: 15,
      style: {
        fontSize: 20,
      },
    },
    series: dataset
  });
}

function setBrightnessOfGrey(average) {
  switch (true) {
    case (average == null):
      return 'white';
    case (average <= 1):
      return '#DCDCDC';
    case (average <= 2):
      return '#D3D3D3';
    case (average <= 3):
      return '#C8C8C8';
    case (average <= 4):
      return '#B8B8B8';
    case (average <= 5):
      return '#A9A9A9';
    case (average <= 6):
      return '#989898';
    case (average <= 8):
      return '#888888';
    case (average <= 10):
      return '#707070';
    case (average <= 12):
      return '#585858';
    default:
      return '#303030';
  }
}
